import { NavLink } from "react-router-dom";
export default function Menu(){
    return(
        <div className="menu">
            <ul>
                <li><NavLink to="/">A toi d'agir</NavLink></li>
                <li><NavLink to="/compteur">Compteur de Points</NavLink></li>
                <li><NavLink to="/mots">Mots</NavLink></li>
                <li><NavLink to="/timer">Timer</NavLink></li>
            </ul>
        </div>
    )
}