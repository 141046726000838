import { NavLink } from "react-router-dom";

export default function Compteur(props){
    const { participants, handleDeleteParticipant} = props;
    console.log(props);

    function clickplus(id){
        var nbp = document.getElementById(id+"1").innerHTML;
        var click = parseInt(nbp) + 1;
        document.getElementById(id + "1").innerText = click;
    }
    
    function clickmoins(id){
        var nbp = document.getElementById(id + "1").innerHTML;
        var click = parseInt(nbp) - 1;
        document.getElementById(id + "1").innerText = click;
    }

    function vainqueur(){
        var participant = participants;
        for(var v =0; v < participant.length; v++){
            participant[v].point = 0;
        }
        for(var j =0; j < participant.length; j++){
            participant[j].point = parseInt(document.getElementById(participant[j].idpart + "1").innerHTML);
        }

        var changed;
        do{
            changed = false;
            for(var i=0; i < participant.length-1; i++) {
                if(participant[i].point < participant[i+1].point) {
                    var tmp = participant[i];
                    participant[i] = participant[i+1];
                    participant[i+1] = tmp;
                    changed = true;
                }
            }
        } while(changed);

        
        if(participant[0].point>participant[1].point){
            document.getElementById("gagnant").innerHTML = participant[0].name;
            
        }else{   
            document.getElementById("gagnant").innerHTML = "Personne";
        }
                
    }

    function reset(){
        var participant = participants;
        for(var j =0; j < participant.length; j++){
            participant[j].point = 0;
            document.getElementById(participant[j].idpart + "1").innerHTML = 0;
        }
    }

    return(
        <>
            <div className="container">
            {
                participants.map(participant => (
                    <div keys={participant.idpart.toString()} id={participant.idpart} className="Compteur">
                        <div className="top">
                            <h1>{participant.name}</h1>
                            <button className="delete" onClick={() => handleDeleteParticipant(participant.idpart)}>X</button>
                        </div>
                        <div className="bouton">
                            <button onClick={() => clickmoins(participant.idpart)}>-</button>
                            <p id={participant.idpart + "1"}>{participant.point}</p>
                            <button onClick={() => clickplus(participant.idpart)}>+</button>
                        </div>
                    </div>
                ))
            }
            </div>
            <div keys={Math.random()} id="vainqueur">
                <h2>Vainqueur :</h2>
                <p id="gagnant">Personne</p>
                <button onClick={() => vainqueur()}>Qui gagne ?</button>
            </div>
            <div className="nav1">   
                <div className="navl">
                    <NavLink className="navla" to="/addPersonne">Ajouter une personne</NavLink>
                </div>
                <button className="reset" onClick={() => reset()}>Reset 0</button>
            </div>
        </>
    )
}