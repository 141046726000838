import { Routes, Route} from 'react-router-dom';
import './css/app.css';
import Home from './Component/Home';
import Menu from './Component/Menu';
import Compteur from './Component/Compteur';
import { useEffect, useState } from 'react';
import AddPersonne from './Component/AddPersonne';
import {v4 as uuidv4} from 'uuid';
import {useLocalStorage} from './hook/useLocalStorage';
import Mots from './Component/Mots';
import Timer from './Component/Timer';

function App() {
  const [participants, setParticipants] = useState([]);
  const STORAGE_KEY = 'participants';
  const [storedPart, setStoredPart] = useLocalStorage(STORAGE_KEY, []);
  useEffect(() => {
    console.log("useEffect with []");
    setParticipants(storedPart);
  }, []);

  useEffect(() => {
    console.log("UseEffect with [participants]");
    setStoredPart(participants);
  }, [participants]);

  function handleAddParticipant(participant){
    console.log('handleAddTechno', participant);
    setParticipants([...participants, {...participant, idpart: uuidv4()}]);
    console.log(participants);
  }

  function handleDeleteParticipant(idpart){
    setParticipants(participants.filter((part) => part.idpart !== idpart));
  }

  return (
    <>
      <Menu/>
      <Routes>
        <Route path="/" element={<Home/>}/>
        <Route path="/addPersonne" element={<AddPersonne handleAddParticipant={handleAddParticipant}/>}/>
        <Route path="/compteur" element={<Compteur participants={participants} handleDeleteParticipant={handleDeleteParticipant}/>}/>
        <Route path="/mots" element={<Mots></Mots>}/>
        <Route path='/timer' element={<Timer></Timer>}/>
      </Routes>
      
    </>
  );
}

export default App;
