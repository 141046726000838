import React, { useState } from "react";
import "../css/timer.css"

function Timer() {
    const [countdown, setCountdown] = useState(5);
    const [centiseconds, setCentiseconds] = useState(0);
    const [isRunning, setIsRunning] = useState(false);
    const [intervalId, setIntervalId] = useState(null);
    const [centisecondsId, setCentisecondsId] = useState(null);
  
    function startTimer() {
      setIsRunning(true);
      let localCountdown = countdown;
      let localCentiseconds = 0;
      let id = setInterval(() => {
        if(localCountdown > 0) {
          setCountdown(localCountdown--);
          localCentiseconds = 0;
        }
        else clearInterval(id);
      }, 1000);
      setIntervalId(id);
  
      let centisecondsId = setInterval(() => {
        if(localCentiseconds < 99) {
          setCentiseconds(localCentiseconds++);
        }
      }, 10);
      setCentisecondsId(centisecondsId);
    }
  
    function stopTimer() {
      clearInterval(intervalId);
      clearInterval(centisecondsId);
      setIsRunning(false);
    }
  
    function resetTimer() {
      clearInterval(intervalId);
      clearInterval(centisecondsId);
      setIsRunning(false);
      setCountdown(5);
      setCentiseconds(0);
    }
  
    if (countdown === 0) {
      return <div>Finished</div>;
    } else {
      return (
        <div className="countdownTimer">
          <div className="countdown-timer-display">
            <h1>{countdown}:{centiseconds < 10 ? "0" + centiseconds : centiseconds}</h1>
          </div>
          <button onClick={startTimer}>Start</button>
          <button onClick={stopTimer}>Stop</button>
          <button onClick={resetTimer}>Reset</button>
        </div>
      );
    }
  }
  
  export default Timer;