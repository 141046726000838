const data = [{
    "lettres": [
        {
            "name": "a",
            "syllabe": [
                "ai",
                "ar",
                "ae",
                "auv",
                "ari",
                "ana",
                "ass",
                "ag",
                "an",
                "as",
                "ac",
                "ah",
                "aba"
            ]
        },
        {
            "name": "b",
            "syllabe": [
                "bor",
                "ba",
                "bi",
                "be",
                "bo",
                "br",
                "bro",
                "bra"
            ]
        },
        {
            "name": "c",
            "syllabe": [
                "co",
                "cor",
                "conn",
                "cti",
                "cher",
                "ch",
                "ce",
                "ca",
                "ci",
                "cr",
                "cc"
            ]
        },
        {
            "name": "d",
            "syllabe": [
                "de",
                "di",
                "des",
                "do",
                "dr",
                "da",
                "die",
                "du"
            ]
        },
        {
            "name": "e",
            "syllabe": [
                "eta",
                "er",
                "ech",
                "eri",
                "ett",
                "eu",
                "ent",
                "emb",
                "es",
                "en",
                "eur",
                "eat",
                "esc",
                "et",
                "ec",
                "eti",
                "ere"
            ]
        },
        {
            "name": "f",
            "syllabe": [
                "fie",
                "ff",
                "fr",
                "fa",
                "fi",
                "fe",
                "fil"
            ]
        },
        {
            "name": "g",
            "syllabe": [
                "gri",
                "geo",
                "gra",
                "gon",
                "gu",
                "ga",
                "ge",
                "gi",
                "gg"
            ]
        },
        {
            "name": "h",
            "syllabe": [
                "hi",
                "ho",
                "hl",
                "he",
                "hy",
                "hum"
            ]
        },
        {
            "name": "i",
            "syllabe": [
                "ibu",
                "io",
                "if",
                "ie",
                "ien",
                "iqu",
                "isu",
                "ieu",
                "ier",
                "ifi",
                "il",
                "ib",
                "ia",
                "in",
                "is",
                "ip",
                "idi"
            ]
        },
        {
            "name": "j",
            "syllabe": [
                "ja",
                "jec",
                "je"
            ]
        },
        {
            "name": "k",
            "syllabe": [
                "ka",
                "ke",
                "ki"
            ]
        },
        {
            "name": "l",
            "syllabe": [
                "lio",
                "lux",
                "li",
                "lex",
                "lan",
                "le",
                "lo",
                "la",
                "lai"
            ]
        },
        {
            "name": "m",
            "syllabe": [
                "mu",
                "mer",
                "me",
                "mmo",
                "mba",
                "ma",
                "mau",
                "mo",
                "man",
                "mom",
                "mor"
            ]
        },
        {
            "name": "n",
            "syllabe": [
                "nc",
                "nta",
                "nt",
                "ns",
                "nti",
                "nn",
                "nd",
                "nam",
                "no",
                "na",
                "nto",
                "ne",
                "ni"
            ]
        },
        {
            "name": "o",
            "syllabe": [
                "og",
                "oc",
                "ons",
                "oul",
                "ot",
                "ol",
                "olo",
                "onn",
                "om",
                "oxy"
            ]
        },
        {
            "name": "p",
            "syllabe": [
                "peu",
                "pa",
                "par",
                "pi",
                "po",
                "pe"
            ]
        },
        {
            "name": "q",
            "syllabe": [
                "que",
                "quo",
                "qui",
                "qua"
            ]
        },
        {
            "name": "r",
            "syllabe": [
                "ro",
                "rq",
                "re",
                "ra",
                "rs",
                "rat",
                "rez",
                "rca",
                "rpe",
                "ris",
                "rt",
                "ranb",
                "rer"
            ]
        },
        {
            "name": "s",
            "syllabe": [
                "ses",
                "st",
                "sin",
                "ser",
                "ss",
                "sio",
                "sur",
                "sr",
                "sa",
                "se",
                "si",
                "su",
                "so",
                "sy"
            ]
        },
        {
            "name": "t",
            "syllabe": [
                "tua",
                "te",
                "ter",
                "teu",
                "ta",
                "tar",
                "tio",
                "tal",
                "tr",
                "ti",
                "to"
            ]
        },
        {
            "name": "u",
            "syllabe": [
                "uq",
                "ucl",
                "ut",
                "ule",
                "utr",
                "ust",
                "ua"
            ]
        },
        {
            "name": "v",
            "syllabe": [
                "vu",
                "va",
                "vi",
                "ve",
                "vo",
                "vri"
            ]
        },
        {
            "name": "w",
            "syllabe": [
                "wa",
                "we",
                "wi",
                "wan"
            ]
        },
        {
            "name": "x",
            "syllabe": [
                "xan",
                "xe",
                "xh",
                "xi",
                "xim"
            ]
        },
        {
            "name": "y",
            "syllabe": [
                "ya",
                "yi",
                "ye",
                "yo",
                "you",
                "yua",
                "your"
            ]
        },
        {
            "name": "z",
            "syllabe": [
                "za",
                "ze",
                "zi",
                "zo",
                "zy",
                "zon"
            ]
        }
    ]
}]

export default data