import data from "./data";

export default function Mots(){
    var sauv;
    function genererlettre(){
        console.log(data[0])
        var al = Math.floor(Math.random() * 25);
        sauv = al;
        for(let i=0; i<26; i++){
            if(i === al){
                console.log(data[0].lettres[i].name.toString())
                document.getElementById("lettre").innerHTML = data[0].lettres[i].name.toString();
            }
        }  
    }

    function generersyllabe(lettre,sauv){
        console.log(sauv)
        console.log(data[0].lettres[sauv].syllabe[0])
        if(lettre == "Aucune"){
            genererlettre();
        }
        var compt = 0;
        for(let i = 0; i < 30; i++){
            if(data[0].lettres[sauv].syllabe[i] == null){
                console.log(data[0].lettres[sauv].syllabe[i]);
                break;
            }else{
                console.log(data[0].lettres[sauv].syllabe[i]);
                compt = i;
            }
        }
        console.log(compt)
        var al = Math.floor(Math.random()*compt);
        document.getElementById("syllabe").innerHTML = data[0].lettres[sauv].syllabe[al].toString();
    }

    return(
        <div>
            <div className="Génération">
                <h2 id="lettre">a</h2>
                <h2 id="syllabe">Aucune</h2>
            </div>
            <div className="btn">
                <button onClick={() => genererlettre()}>Générer lettre</button>
                <button onClick={() => generersyllabe(document.getElementById('lettre').innerHTML,sauv)}>Générer syllabe</button>
            </div>
        </div>
    )
}