import { useState } from "react";
export default function AddPersonne(props){
    const [participant, setParticipant] = useState({
        name: '',
        point: 0
    });

    const {handleAddParticipant} = props;

    function handleSubmit(evt){
        evt.preventDefault();
        handleAddParticipant(participant);
        setParticipant({
            name: '',
            point: 0
        })
    }

    function handleChange(evt){
        const {name, value} = evt.target;
        setParticipant({...participant, [name]: value});
    }

    return(
        <form onSubmit={(evt)=>handleSubmit(evt)}>
            <label htmlFor="participant">Nom du Participant :</label>
            <input name="name" id="name" type="text" value={participant.name} onChange={(evt)=> handleChange(evt)}></input>
            <input name="point" id="point" type="number" value={participant.point} onChange={(evt)=> handleChange(evt)}></input>
            <input type="submit" value="Add participant"></input>
        </form>
    )
}