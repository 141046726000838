export default function Home(){

    function getRandomInt(max) {
        var mavar = Math.floor(Math.random() * max);
        document.getElementById("result").innerHTML = mavar;
        if(mavar < 5){
            document.getElementById("success").innerHTML = "Echec critique, trop nul !"
        }else if(mavar > 16){
            document.getElementById("success").innerHTML = "Réussite critique, trop fort !"
        }else{
            document.getElementById("success").innerHTML = ""
        }
      return mavar;
    }

    return (
        <div className="home">
            <h1 id="result" className="homeh1">0</h1>
			<p id="success">Rien</p>
			<button className="homebutton" onClick={() => getRandomInt(20)}>Générer nombre aléatoire</button>
        </div>
    )
}